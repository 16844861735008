import React from 'react'
import HomepageFifthComp from 'components/homepage/homepageFifthComp'
import HomepageReviews from 'components/homepage/homepageReviews'
import ServiceClients from 'components/ServiceClients/variation1'
import { ServiceClientsContactUsPageData } from 'components/ServiceClients/config'

const ABtestContactNewComp = () => {
  return (
    <div className="bg-gradient-to-b from-white to-[#E8F1F5]">
      <ServiceClients data={ServiceClientsContactUsPageData} />
      <HomepageReviews bgCol="bg-none" newCarousel />
    </div>
  )
}

export default ABtestContactNewComp

import React from 'react'
import ServiceClientCard from './ServiceClientCard'

const colors = ['#0088FE', '#00C49F', '#FFBB28']
const delay = 5000

function ServiceClientsCarousel({ data }) {
  const [index, setIndex] = React.useState(0)
  const timeoutRef = React.useRef(null)

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  React.useEffect(() => {
    resetTimeout()
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1,
        ),
      delay,
    )

    return () => {
      resetTimeout()
    }
  }, [index])

  const slideOneData = data.slice(0, 4)
  const slideTwoData = data.slice(4, 8)
  const slideThreeData = data.slice(8, 12)

  return (
    <div className="slideshow">
      <div
        className="w-full slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        <div
          className={`client-slide relative ${index === 0 ? ' active' : ''}`}
        >
          <ServiceClientCard slideData={slideOneData} gapX="gap-x-16" />
        </div>
        <div
          className={`client-slide relative ${index === 1 ? ' active' : ''}`}
        >
          <ServiceClientCard slideData={slideTwoData} gapX="gap-x-16" />
        </div>
        <div
          className={`client-slide relative ${index === 2 ? ' active' : ''}`}
        >
          <ServiceClientCard slideData={slideThreeData} gapX="gap-x-24" />
        </div>
      </div>

      <div className="w-full slideshowDots sm:mt-0 mt-8">
        {colors.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? ' active' : ''}`}
            onClick={() => {
              setIndex(idx)
            }}
          ></div>
        ))}
      </div>
    </div>
  )
}

export default ServiceClientsCarousel

import React from "react";
import OptimizedImage from "hooks/OptimizedImage";

export default function (title, icon, className,lazyload = true) {
  if (icon === "amazon") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/clients/amazon.svg"
        alt={title}
        lazyload={lazyload}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "google") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/clients/google.svg"
        alt={title}
        lazyload={lazyload}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "microsoft") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/clients/microsoft.svg"
        alt={title}
        lazyload={lazyload}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "youtube") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/clients/youtube.svg"
        alt={title}
        lazyload={lazyload}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "bloomberg") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/clients/bloomberg.svg"
        alt={title}
        lazyload={lazyload}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "spotify") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/clients/spotify.svg"
        alt={title}
        lazyload={lazyload}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "wix") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/clients/wix.svg"
        alt={title}
        lazyload={lazyload}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "hsbc") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/clients/hsbc.svg"
        alt={title}
        lazyload={lazyload}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "canon") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/images/canon.svg"
        alt={title}
        lazyload={lazyload}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "mgm") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/clients/mgm.png"
        alt={title}
        lazyload={lazyload}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "code") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/clients/code.svg"
        alt={title}
        lazyload={lazyload}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "sap") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/clients/sap.svg"
        alt={title}
        lazyload={lazyload}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "iso9001:2015") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/images/new-homepage-assets/accreditation-icons/iso.png"
        alt={title}
        lazyload={lazyload}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "bbc") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/images/new-homepage-assets/desktop/bbc_logo.svg"
        alt={title}
        lazyload={lazyload}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "ashrena") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/images/1.png"
        alt={title}
        lazyload={lazyload}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "ata") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/images/new-homepage-assets/accreditation-icons/ata.png"
        alt={title}
        lazyload={lazyload}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "clutch") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/images/new-homepage-assets/accreditation-icons/clutch.png"
        alt={title}
        lazyload={lazyload}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "uscis") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/images/new-homepage-assets/accreditation-icons/uscis.svg"
        alt={title}
        lazyload={lazyload}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "iso9001:2015gold") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/quality-policy/accreditation/9001_big.webp"
        alt={title}
        lazyload={lazyload}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "iso17100:2015gold") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/quality-policy/accreditation/17100_big.webp"
        alt={title}
        lazyload={lazyload}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "iso18587:2017gold") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/quality-policy/accreditation/18587_big.webp"
        alt={title}
        lazyload={lazyload}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "iso9001:2015certificate") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/iso_9001.webp"
        alt={title}
        lazyload={lazyload}
        className={className ? className : "w-20 h-auto"}
      />
    );
  }
}

import ContactUs from "components/ContctUs";
import Layout from "components/layout";
import SEO from "components/seo";
import React from "react";

const index = () => {
  return (
    <Layout>
      <SEO
        title="Contact us | Tomedes.com"
        description="Contact us: The Tomedes translators team in USA and worldwide will assist you with any translation issue, translation question or feedback."
        keywords="US translators, translators in Europe, contact translators"
        slug="/contactus.php"
      />
      <ContactUs />
    </Layout>
  );
};

export default index;

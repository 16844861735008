export const ServiceClientsContactUsPageData = {
  title: "Join our 95,000 satisfied business customers today.",
  highlightWords: ["95,000+"],
  highlightedColor: "yellow",
  subtitle:
    "Tomedes takes pride in our 98% satisfaction rate from over 95,000 businesses and Fortune 500 companies worldwide. Our teams of native translators ensure that all translation projects are quickly delivered and consistently up to ISO standards. We also offer a 1-year guarantee on all our translation projects.",
  clients: [
    {
      id: 1,
      title: "Amazon",
      icon: "amazon",
      className: "w-24 h-auto",
    },
    {
      id: 2,
      title: "Google",
      icon: "google",
      className: "w-20 h-auto",
    },
    {
      id: 3,
      title: "Microsoft",
      icon: "microsoft",
      className: "w-24 h-auto",
    },
    {
      id: 4,
      title: "Youtube",
      icon: "youtube",
      className: "w-24 h-auto",
    },
    {
      id: 5,
      title: "Bloomberg",
      icon: "bloomberg",
      className: "w-24 h-auto",
    },
    {
      id: 6,
      title: "Spotify",
      icon: "spotify",
      className: "w-20 h-auto",
    },
    {
      id: 7,
      title: "Wix",
      icon: "wix",
      className: "w-20 h-auto",
    },
    {
      id: 8,
      title: "HSBC",
      icon: "hsbc",
      className: "w-24 h-auto",
    },
    {
      id: 9,
      title: "Canon",
      icon: "canon",
      className: "w-24 h-auto",
    },
    {
      id: 10,
      title: "MGM",
      icon: "mgm",
      className: "w-11 h-auto",
    },
    {
      id: 11,
      title: "Code",
      icon: "code",
      className: "w-9 h-auto",
    },
    {
      id: 12,
      title: "SAP",
      icon: "sap",
      className: "w-12 h-auto",
    },
  ],
};

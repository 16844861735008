import Reviews from 'components/homepage/reviews'
import TranslateAgency from 'components/homepage/translateAgency'
import React, { useState, useEffect } from 'react'
import ABtestContactNewComp from './ab-test-new-comp'
import FirstContact from './firstContact'
import SecondContact from './secondContact'

const ContactUs = () => {
  const useExperiment = (experimentId) => {
    const [variant, setVariant] = useState()

    useEffect(() => {
      (async () => {
        // if (window.dataLayer) {
        //   await window.dataLayer.push({ event: 'optimize.activate' })
        // }
        const intervalId = setInterval(() => {
          if (window.google_optimize !== undefined) {
            // Set the variant to the state.
            setVariant(window.google_optimize.get(experimentId))
            clearInterval(intervalId)
          }
        }, 100)
      })()
    }, [])
    return variant
  }

  // To switch variant during development comment one and uncomment other
  // const variant = useExperiment('w4y-P__bT12Xe_MjZNiyeA')
  const variant = 1

  return (
    <div>
      <FirstContact styleOne={variant} />
      <SecondContact styleOne={variant} />
      <>
        {variant == '1' ? (
          <>
            <ABtestContactNewComp />
          </>
        ) : (
          <>
            <TranslateAgency
              head="Trusted by Business Customers"
              colorHead="Worldwide"
              BackgroundCol="bg-darkBlue"
            />
            <Reviews backgroundCol="bg-midBlue" head=" " headH2 content />
          </>
        )}
      </>
    </div>
  )
}

export default ContactUs

import * as React from "react";
// import './ServiceClients.style.css'
import BoxContainer from "components/BoxContainer";
import Text from "components/Text";
import imageResolver from "utils/image-resolver";
import ServiceClientsCarousel from "components/ServiceClientsCarousel";

const ServiceClients = ({
  data,
  background = "bg-alice-blue",
  noTitle = false,
}) => {
  // let windowSize

  // if (typeof window !== 'undefined') {
  //   windowSize = window ? window.screen.width : 1000
  // }

  return (
    <BoxContainer
      background={background}
      className="items-center text-center py-[80px] md:px-8"
    >
      {!noTitle && (
        <div className="px-5 md:px-0 md:w-10/12 m-auto pb-12 text-midBlue 2xl:max-w-6xl">
          <Text
            as="h2"
            highlightedText={data?.highlightWords}
            highlightedColor={data?.highlightedColor}
            className="text-midBlue text-3xl leading-[45px] sm:leading-relaxed sm:text-3xl font-secondary font-bold mb-3 max-w-xl m-auto"
          >
            {data?.title}
          </Text>
          <Text
            className="text-lg leading-8 text-midBlue font-opensans sm:block hidden"
            as="div"
          >
            {data?.subtitle}
          </Text>
        </div>
      )}
      <div className="sm:hidden flex mb-7">
        <ServiceClientsCarousel data={data?.clients} />
      </div>
      <div className="sm:grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-14 items-center justify-items-center max-w-4xl mx-auto hidden">
        {data?.clients.map(({ title, icon, className }, i) => {
          return <div key={i}>{imageResolver(title, icon, className)}</div>;
        })}
      </div>
      {data?.review && (
        <div className="flex flex-row items-center justify-center">
          <Text
            className="text-[23px] font-medium font-opensans text-white"
            as="p"
          >
            {data?.review?.text}
          </Text>
          <img
            className="inline h-6 pl-5"
            width="auto"
            src={data?.review?.icon}
            alt={"trustpilot"}
          />
        </div>
      )}
    </BoxContainer>
  );
};

export default ServiceClients;

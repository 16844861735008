import React from 'react'
import imageResolver from 'utils/image-resolver'

const ServiceClientCard = ({ slideData, gapX }) => {
  return (
    <div
      className={`flex justify-between flex-wrap gap-y-14 w-100 ${gapX} mx-8`}
    >
      {slideData.map((item) => (
        <div className="self-start">
          {imageResolver(item.title, item.icon, item.className, false)}
        </div>
      ))}
    </div>
  )
}

export default ServiceClientCard

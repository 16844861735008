import React from "react";
import HomepageReviewCards from "../HomepageReviewCard";
import OptimizedImage from "hooks/OptimizedImage";
import RatingCarousel from "components/MultilingualSeoServices/Carousel";

export default function HomepageReviews({ bgCol, newCarousel }) {
  return (
    <section
      className={`${
        bgCol ? bgCol : "bg-gradient-to-b from-white to-gradient1"
      }pt-20 pb-16`}
    >
      <div className="container mx-auto px-4 max-w-6xl">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h2
              className="font-opensans xs:font-secondary font-bold"
              style={{
                fontSize: "clamp(1.5rem, 3vw, 2.75rem)",
                lineHeight: "clamp(2rem, 4vw, 3.5rem)",
              }}
            >
              <span className="text-orange">4.9 out of 5</span>

              <br />
              <span className="text-midBlue">Aggregate Rating</span>
              <p className="font-opensans xs:font-secondary font-normal text-24 text-midBlue mt-4">
                (Out of 1798 Verified Reviews Online)
              </p>
            </h2>
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/images/review_stars.svg"
              alt="review star"
              className="mt-5 mb-2 w-[343px] sm:w-[403px] h-[49px] sm:h-[59px]"
            />
            <p
              className="font-opensans  text-midBlue text-left mt-5"
              style={{
                fontSize: "clamp(1rem, 1.4vw, 1.25rem)",
                lineHeight: "clamp(1.5rem, 3vw, 1.75rem)",
              }}
            >
              Tomedes takes pride in 95,000+ satisfied customers, around the
              globe, a number that includes several Fortune 500 companies. We
              have a 4.9 out of 5 aggregate star rating from
              <span className="font-bold"> 1,798 online reviews.</span>
            </p>
            <p className="mt-12 mb-12 md:mb-0">
              <a
                href="/reviews.php"
                target="_blank"
                className="uppercase rounded-full bg-orange hover:bg-orange text-white cursor-pointer text-17 font-opensans font-semibold py-4 px-8 w-[253px] text-center transition-all duration-300 "
              >
                SEE ALL REVIEWS
              </a>
            </p>
          </div>
          {newCarousel ? (
            <RatingCarousel paginationDots="left" />
          ) : (
            <HomepageReviewCards />
          )}
        </div>
      </div>
    </section>
  );
}
